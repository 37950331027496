import {MuWidget} from "mu-widget/lib/MuWidget";

export class CopyBlock extends MuWidget
{
	beforeIndex() {
		this.container.innerHTML = '<span mu-id="content" class="copy-block__content">' + this.container.innerHTML + '</span><span mu-id="btCopy" class="copy-block__bt-copy"></span>';
	}

	btCopy_click() {
		this.ui.content.contentEditable = "true";
		this.ui.content.setAttribute("contenteditable", "true");
		this.ui.content.focus();
		document.execCommand('selectAll');
		document.execCommand("copy");
		this.ui.content.contentEditable = "false";
		this.ui.content.setAttribute("contenteditable", "false");
	}
}